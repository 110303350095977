import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "src/components/Common/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardAdmin";
import ReporteDiarioVisitas from "./reportes/Visitas";

const Dashboard = lazy(() => import("./dashboardAdmin"));
const Presupuestar = lazy(() => import("./presupuestar"));
const NewPresupuestar = lazy(() => import("./presupuestar/New/New"));
const ViewPresupuestar = lazy(() => import("./presupuestar/Ver/ver"));
const AreasComunales = lazy(() => import("./areasComunales"));
const AreaComunal = lazy(() => import("./areasComunales/AreaComunal"));
const ReservasAprobar = lazy(() =>
  import("./areasComunales/reservaAreasComunales")
);
const PresupuestarValidar = lazy(() =>
  import("./presupuestar/Validar/Validar")
);
const Residente = lazy(() => import("./residente"));
const Tags = lazy(() => import("./tags"));
const Placas = lazy(() => import("./placas"));
const Mudanza = lazy(() => import("./mudanza/ManageMudanzaRequests"));
const MudanzaUser = lazy(() => import("./mudanza/ManageUserMudanzaRequests"));
const BitacoraMudanza = lazy(() => import("./mudanza/BitacoraRequests"));

const ReporteEgresos = lazy(() => import("./reportes/egresos"));
const Proveedores = lazy(() => import("./proveedores/Proveedor"));
const CuentasPorPagar = lazy(() =>
  import("./proveedores/PagosAndPedidos/DebtsToPay")
);
const RecurrentBillToPay = lazy(() =>
  import("./proveedores/PagosAndPedidos/RecurrentBillToPay")
);
const ListReconcile = lazy(() => import("./conciliaciones"));
const Conciliar = lazy(() =>
  import("./conciliaciones/components/Transactions")
);
const DetailReconcile = lazy(() =>
  import("./conciliaciones/components/DetailReconcile")
);
const CuentasBancarias = lazy(() => import("./metodosDePago/cuentasBancarias"));
const Cajas = lazy(() => import("./metodosDePago/cajas"));
const Activos = lazy(() => import("./metodosDePago/activos"));
const RubrosIngresos = lazy(() => import("./rubros/RubrosIngresos"));
const RubrosEgresos = lazy(() => import("./rubros/RubrosEgresos"));
const IngresosSinIdentificar = lazy(() => import("./IngresosSinIdentificar"));
const Ingresos = lazy(() => import("./ingresos"));
const IngresosAnual = lazy(() => import("./reportes/ingresosAnual"));
const Egresos = lazy(() => import("./egresos"));
const AprobacionDePagos = lazy(() => import("./aprobacionDePagos"));
const ReporteIngresosVsEgresos = lazy(() => import("./reportes/ingVsEgr"));
const ReporteHistoricoCartera = lazy(() =>
  import("./reportes/historicoCartera")
);
const ReporteCartera = lazy(() => import("./reportes/cartera"));
const ReporteCuentasporCobrar = lazy(() =>
  import("./reportes/cuentasporcobrar")
);
const ReporteIngresos = lazy(() => import("./reportes/ingresos"));
const ReporteCarteraUsuario = lazy(() =>
  import("./reportes/carteraPorUsuario")
);
const ReporteConsolidado = lazy(() => import("./reportes/consolidado"));
const ReporteFinanciero = lazy(() => import("./reportes/financiero"));
const IssuesReport = lazy(() => import("./issuesReport"));
const Mantenimiento = lazy(() => import("./mantenimiento"));
const Votacion = lazy(() => import("./votacion"));
const Notificaciones = lazy(() => import("./notificaciones"));
const Repositorio = lazy(() => import("./repositorio"));
const Archive = lazy(() => import("./repository"));
const NotFoundView = lazy(() => import("../errors/NotFoundView"));
const GestionGuardia = lazy(() => import("./gestionGuardia"));
const EditComplexe = lazy(() => import("./editComplexe"));
const Guardias = lazy(() => import("./guardias"));
const FAQ = lazy(() => import("../FAQ"));
const VisitasGuardia = lazy(() => import("./reportes/visitasGuardia"));
const Settings = lazy(() => import("./Settings"));
const CruceCuentas = lazy(() => import("./cruceCuentas"));
const Anulaciones = lazy(() => import("./anulaciones"));
const Programados = lazy(() => import("./programados"));
const ScheduledMails = lazy(() => import("./Settings/ScheduledMails"));
const HistoricoFondos = lazy(() => import("./historicoFondos"));
const Logs = lazy(() => import("./logs"));
const Admin = ({ match }) => (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route
          path={`${match.url}/dashboard`}
          exact
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          path={`${match.url}/presupuestar`}
          exact
          render={(props) => <Presupuestar {...props} />}
        />
        <Route
          path={`${match.url}/historicoFondos`}
          exact
          render={(props) => <HistoricoFondos {...props} />}
        />
        <Route
          path={`${match.url}/presupuestar/new`}
          exact
          render={(props) => <NewPresupuestar {...props} />}
        />
        <Route
          path={`${match.url}/presupuestar/view/:threadKey`}
          exact
          render={(props) => <ViewPresupuestar {...props} />}
        />
        <Route
          path={`${match.url}/areascomunales`}
          exact
          render={(props) => <AreasComunales {...props} />}
        />
        <Route
          path={`${match.url}/areascomunales/areacomunal/:areaId`}
          exact
          render={(props) => <AreaComunal {...props} />}
        />
        <Route
          path={`${match.url}/areascomunales/areacomunal`}
          exact
          render={(props) => <AreaComunal {...props} />}
        />
        <Route
          path={`${match.url}/reservasxaprobar`}
          exact
          render={(props) => <ReservasAprobar {...props} />}
        />
        <Route
          path={`${match.url}/presupuestar/validar/:threadKey`}
          exact
          render={(props) => <PresupuestarValidar {...props} />}
        />
        <Route
          path={`${match.url}/residente`}
          exact
          render={(props) => <Residente {...props} />}
        />
        <Route
          path={`${match.url}/placas`}
          exact
          render={(props) => <Placas {...props} />}
        />
        <Route
          path={`${match.url}/tags`}
          exact
          render={(props) => <Tags {...props} />}
        />
        <Route
          path={`${match.url}/mudanza`}
          exact
          render={(props) => <Mudanza {...props} />}
        />
        <Route
          path={`${match.url}/mudanzaUsuario/:threadKey`}
          exact
          render={(props) => <MudanzaUser {...props} />}
        />
        <Route
          path={`${match.url}/bitacoraMudanza/:placa/unidas/:unidadHabitacional`}
          exact
          render={(props) => <BitacoraMudanza {...props} />}
        />

        <Route
          path={`${match.url}/proveedores`}
          exact
          render={(props) => <Proveedores {...props} />}
        />
        <Route
          path={`${match.url}/proveedores/cuentasporpagar`}
          exact
          render={(props) => <CuentasPorPagar {...props} />}
        />
        <Route
          path={`${match.url}/proveedores/cuentasRecurrentes`}
          exact
          render={(props) => <RecurrentBillToPay {...props} />}
        />
        <Route
          path={`${match.url}/conciliar`}
          exact
          render={(props) => <ListReconcile {...props} />}
        />
        <Route
          path={`${match.url}/conciliar/:id`}
          exact
          render={(props) => <Conciliar {...props} />}
        />
        <Route
          path={`${match.url}/conciliar/:id/detalle`}
          exact
          render={(props) => <DetailReconcile {...props} />}
        />
        <Route
          path={`${match.url}/cuentasBancarias`}
          exact
          render={(props) => <CuentasBancarias {...props} />}
        />
        <Route
          path={`${match.url}/cajas`}
          exact
          render={(props) => <Cajas {...props} />}
        />
        <Route
          path={`${match.url}/activos`}
          exact
          render={(props) => <Activos {...props} />}
        />
        <Route
          path={`${match.url}/rubros/rubrosIngresos`}
          exact
          render={(props) => <RubrosIngresos {...props} />}
        />
        <Route
          path={`${match.url}/rubros/rubrosEgresos`}
          exact
          render={(props) => <RubrosEgresos {...props} />}
        />
        <Route
          path={`${match.url}/ingresosNoIdentificados`}
          exact
          render={(props) => <IngresosSinIdentificar {...props} />}
        />
        <Route
          path={`${match.url}/ingresos`}
          exact
          render={(props) => <Ingresos {...props} />}
        />
        <Route
          path={`${match.url}/egresos`}
          exact
          render={(props) => <Egresos {...props} />}
        />
        <Route
          path={`${match.url}/crucecuentas`}
          exact
          render={(props) => <CruceCuentas {...props} />}
        />
        <Route
          path={`${match.url}/anulaciones`}
          exact
          render={(props) => <Anulaciones {...props} />}
        />
        <Route
          path={`${match.url}/transferenciasporaprobar`}
          exact
          render={(props) => <AprobacionDePagos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/ingvsegr`}
          exact
          render={(props) => <ReporteIngresosVsEgresos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/cartera`}
          exact
          render={(props) => <ReporteCartera {...props} />}
        />

        <Route
          path={`${match.url}/reportes/historicoCartera`}
          exact
          render={(props) => <ReporteHistoricoCartera {...props} />}
        />

        <Route
          path={`${match.url}/reportes/cuentasporcobrar`}
          exact
          render={(props) => <ReporteCuentasporCobrar {...props} />}
        />
        <Route
          path={`${match.url}/reportes/ingresos`}
          exact
          render={(props) => <ReporteIngresos {...props} />}
        />

        <Route
          path={`${match.url}/reportes/ingresosAnual`}
          exact
          render={(props) => <IngresosAnual {...props} />}
        />

        <Route
          path={`${match.url}/reportes/financiero`}
          exact
          render={(props) => <ReporteFinanciero {...props} />}
        />
        <Route
          path={`${match.url}/reportes/egresos`}
          exact
          render={(props) => <ReporteEgresos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/carteraUsuario`}
          exact
          render={(props) => <ReporteCarteraUsuario {...props} />}
        />
        <Route
          path={`${match.url}/reportes/consolidado`}
          exact
          render={(props) => <ReporteConsolidado {...props} />}
        />
        <Route
          path={`${match.url}/reportes/ReporteDiarioVisitas`}
          exact
          render={(props) => <ReporteDiarioVisitas {...props} />}
        />
        <Route
          path={`${match.url}/informeProblemas`}
          exact
          render={(props) => <IssuesReport {...props} />}
        />
        <Route
          path={`${match.url}/mantenimiento`}
          exact
          render={(props) => <Mantenimiento {...props} />}
        />
        <Route
          path={`${match.url}/votacion`}
          exact
          render={(props) => <Votacion {...props} />}
        />
        <Route
          path={`${match.url}/notificaciones`}
          exact
          render={(props) => <Notificaciones {...props} />}
        />
        <Route
          path={`${match.url}/programados`}
          exact
          render={(props) => <Programados {...props} />}
        />
        <Route
          path={`${match.url}/configuraciones/correosprogramados`}
          exact
          render={(props) => <ScheduledMails {...props} />}
        />
        <Route
          path={`${match.url}/repositorio`}
          exact
          render={(props) => <Repositorio {...props} />}
        />
        <Route
          path={`${match.url}/archivo`}
          exact
          render={(props) => <Archive {...props} />}
        />
        <Route
          path={`${match.url}/gestionGuardia`}
          exact
          render={(props) => <GestionGuardia {...props} />}
        />
        <Route
          path={`${match.url}/reportes/visitasGuardia`}
          exact
          render={(props) => <VisitasGuardia {...props} />}
        />
        <Route
          path={`${match.url}/editarConjunto`}
          exact
          render={(props) => <EditComplexe {...props} />}
        />
        <Route
          path={`${match.url}/conjuntos/configuraciones`}
          exact
          render={(props) => <Settings {...props} />}
        />
        <Route
          path={`${match.url}/gestionarGuardias`}
          exact
          render={(props) => <Guardias {...props} />}
        />
        <Route
          path={`${match.url}/faq`}
          exact
          render={(props) => <FAQ {...props} />}
        />
        <Route
          path={`${match.url}/logs`}
          exact
          render={(props) => <Logs {...props} />}
        />
        <Route render={(props) => <NotFoundView {...props} />} />
      </Switch>
    </Suspense>
  </DashboardLayout>
);

export default Admin;
