import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectConjunto } from "src/redux/slices/complexSlice";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
  Tooltip,
} from "@material-ui/core";
import {
  AccountBalance as AreasComunales,
  AccountBalanceWallet,
  Assessment,
  Build,
  CheckBox,
  Class,
  Contacts,
  Dashboard,
  EventNote as AreaRes,
  Folder,
  HomeWork as AreaManager,
  HowToReg,
  HowToVote,
  Menu,
  Payment,
  People as Residente,
  Search,
  SupervisedUserCircle,
  TrendingUp,
  Transform,
  CallReceived,
  CallMade,
  Ballot,
  Policy,
  SyncAlt,
  Email,
  NotInterested,
  Assignment,
  LocalShippingOutlined,
  Dns,
  VerifiedUser,
  DriveEta
} from "@material-ui/icons"
import { getModulos } from "src/services/admin/resident";
import bgImage from "src/assets/img/nav_nachos@2x.png";
import LogoDown from "src/components/Common/LogoDown";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import NavItem from "./NavItem";
/* classic navbar options */
const classicAdmin = [
  {
    items: [
      {
        title: "Dashboard",
        icon: Dashboard,
        href: "/administrador/dashboard",
      },
      {
        title: "Áreas comunales",
        icon: AreasComunales,
        items: [
          {
            title: "Administrar Áreas",
            icon: AreaManager,
            href: "/administrador/areascomunales",
          },
          {
            title: "Administrar Reservas",
            icon: AreaRes,
            href: "/administrador/reservasxaprobar",
          },
        ],
      },
      {
        title: "Residentes",
        icon: Residente,
        href: "/administrador/residente",
      },
      {
        title: "Controles de acceso",
        icon: VerifiedUser,
        items: [
          {
            title: "Placas",
            icon: DriveEta,
            href: "/administrador/placas",
          },
          {
            title: "Tags vehiculares",
            icon: Dns,
            href: "/administrador/tags",
          },
        ],
      },
      {
        title: "Mudanzas",
        icon: LocalShippingOutlined,
        href: "/administrador/mudanza",
      },
      {
        title: "Proveedores",
        icon: Contacts,
        href: "/administrador/proveedores",
        items: [
          {
            title: "Proveedores",
            href: "/administrador/proveedores",
          },
          {
            title: "Cuentas por pagar",
            href: "/administrador/proveedores/cuentasporpagar",
          },
        ],
      },
      {
        title: "Conciliación",
        icon: CheckBox,
        href: "/administrador/conciliar",
      },
      {
        title: "Presupuestar",
        icon: TrendingUp,
        href: "/administrador/presupuestar",
      },
      {
        title: "Comunicaciones",
        icon: Email,
        items: [
          {
            title: "Notificaciones",
            href: "/administrador/notificaciones",
          },
          {
            title: "Cobros / Vencimientos",
            href: "/administrador/programados",
          },
        ],
      },
      {
        title: "Cajas / Bancos / Activos",
        icon: AccountBalanceWallet,
        items: [
          {
            title: "Cuentas Bancarias",
            href: "/administrador/cuentasBancarias",
          },
          {
            title: "Cajas",
            href: "/administrador/cajas",
          },
          {
            title: "Activos",
            href: "/administrador/activos",
          },
          {
            title: "Transferencia de fondos",
            icon: MenuBookIcon,
            href: "/administrador/historicoFondos",
          },
        ],
      },
      {
        title: "Transacciones",
        icon: Transform,
        items: [
          {
            title: "Ingresos",
            icon: CallReceived,
            href: "/administrador/ingresos",
          },
          {
            title: "Egresos",
            icon: CallMade,
            href: "/administrador/egresos",
          },
          {
            title: "Cruce de cuentas",
            icon: SyncAlt,
            href: "/administrador/crucecuentas",
          },
          {
            title: "Ingresos no identificados",
            icon: CallReceived,
            href: "/administrador/ingresosNoIdentificados",
          },
          {
            title: "Anulados",
            icon: NotInterested,
            href: "/administrador/anulaciones",
          },
        ],
      },
      {
        title: "Rubros",
        icon: Class,
        items: [
          {
            title: "Rubros Ingresos",
            href: "/administrador/rubros/rubrosingresos",
          },
          {
            title: "Rubros Egresos",
            href: "/administrador/rubros/rubrosegresos",
          },
        ],
      },
      {
        title: "Transferencias pendientes",
        icon: Payment,
        href: "/administrador/transferenciasporaprobar",
      },
      {
        title: "Votación",
        icon: HowToVote,
        href: "/administrador/votacion",
      },
      {
        title: "Reportes de Daños",
        icon: Build,
        items: [
          {
            title: "Reportes de Daños",
            href: "/administrador/informeProblemas",
          },
          {
            title: "Calendario de Mantenimientos",
            href: "/administrador/mantenimiento",
          },
        ],
      },
      {
        title: "Archivo",
        icon: Folder,
        href: "/administrador/archivo",
      },
      {
        title: "Reportes",
        icon: Ballot,
        items: [
          {
            title: "Ingresos y Egresos",
            href: "/administrador/reportes/ingvsegr",
          },
          {
            title: "Cuentas por Cobrar",
            href: "/administrador/reportes/cuentasporcobrar",
          },
          {
            title: "Ingresos",
            href: "/administrador/reportes/ingresos",
          },
          {
            title: "Ingresos por Año",
            href: "/administrador/reportes/ingresosAnual",
          },
          {
            title: "Egresos",
            href: "/administrador/reportes/egresos",
          },
          {
            title: "Cartera Vencida",
            href: "/administrador/reportes/cartera",
          },
          {
            title: "Cartera Mensualizada",
            href: "/administrador/reportes/carteraUsuario",
          },
          {
            title: "Historico de Cartera",
            href: "/administrador/reportes/historicoCartera",
          },
          {
            title: "Consolidado",
            href: "/administrador/reportes/consolidado",
          },
          {
            title: "Financiero",
            href: "/administrador/reportes/financiero",
          },
        ],
      },
      {
        title: "Gestion Guardia",
        icon: Policy,
        items: [
          {
            title: "Listas B/N y Visitas Ant.",
            icon: HowToReg,
            href: "/administrador/gestionGuardia",
          },
          {
            title: "Guardias",
            icon: SupervisedUserCircle,
            href: "/administrador/gestionarGuardias",
          },
          {
            title: "Visitas",
            icon: Assessment,
            href: "/administrador/reportes/visitasGuardia",
          },
          {
            title: "Actividad x Hora",
            icon: Assessment,
            href: "/administrador/reportes/ReporteDiarioVisitas",
          },
        ],
      },
      {
        title: "Logs / Registros",
        icon: Assignment,
        href: "/administrador/logs",
      },
      {
        title: "FAQ",
        icon: Search,
        href: "/administrador/faq",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  mobileDrawer: {
    width: 256,
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const conjunto = useSelector(selectConjunto);
  const location = useLocation();
  const [modulos, setModulos] = useState({});
  const [selectedNavItem2, setSelectedNavItem2] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const mod = getModulos(
      {
        next: (querySnapshot) => {
          const Items = querySnapshot.data();
          setModulos(Items);
        },
      },
      conjunto.id
    );
    return mod;
  }, [conjunto.id]);

  const renderNavItems = ({ items, pathname, depth = 0 }) => (
    <List disablePadding>
      {items
        .filter((item) => item)
        .reduce(
          (acc, item) =>
            reduceChildRoutes({
              acc,
              item,
              pathname,
              depth,
            }),
          []
        )}
    </List>
  );

  const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
    const key = item.title + depth;
    if (item.items) {
      const opened = matchPath(pathname, {
        path: item.href,
        exact: false,
      });
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(opened)}
          selectedNavItem={selectedNavItem2}
          setSelectedNavItem={setSelectedNavItem2}
          title={item.title}
        >
          {renderNavItems({ depth: depth + 1, pathname, items: item.items })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <LogoDown />
        </Box>
        <Divider className={classes.dividerColor} variant="middle" />
        <Box p={2}>
          <>
            {classicAdmin.map((section) => (
              <List
                key={section.items[0].title + Math.random()}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                    items: section.items.map((item, index) => {
                      if (index === 3 && item.items) {
                        const placasValid = modulos?.PlacasResidentes;
                        const tagsValid = modulos?.TagsResidentes;
                        if (!placasValid && !tagsValid) {
                          return null;
                        }
                        if (!placasValid && tagsValid) {
                          return {
                            ...item,
                            items: item.items.filter(
                              (subItem, subIndex) => subIndex !== 0
                            ),
                          };
                        }
                        if (placasValid && !tagsValid) {
                          return {
                            ...item,
                            items: item.items.filter(
                              (subItem, subIndex) => subIndex !== 1
                            ),
                          };
                        }
                        return item;
                      }
                      return item;
                    }),
                    pathname: location.pathname,
                  })}
              </List>
            ))}
          </>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden only="lg">
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          variant="temporary"
          open={open}
          onClose={() => setOpen(false)}
        >
          {content}
        </Drawer>
        <Tooltip title="Abrir menú lateral">
          <Button
            style={{
              position: "absolute",
              left: 10,
              top: 10,
              zIndex: 1100,
              backgroundColor: "#051e34",
              color: "#fff",
            }}
            onClick={() => setOpen(true)}
            variant="contained"
          >
            <Menu />
          </Button>
        </Tooltip>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
