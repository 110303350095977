/* eslint-disable no-nested-ternary */
import 'react-notifications-component/dist/theme.css'
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Swal from 'sweetalert2';
import UIfx from 'uifx';
import {
  Button, Dialog, DialogContent, DialogContentText,
  DialogTitle, Grid, makeStyles, Snackbar, SnackbarContent, Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { getComplex, selectCamaraGarita, selectConjunto, selectGarita } from 'src/redux/slices/complexSlice';
import { Check, Close } from '@material-ui/icons';
import { Store } from 'react-notifications-component'
import SplashScreen from 'src/components/Common/SplashScreen';
import axiosInstance from 'src/utils/axios';
import { selectUser } from 'src/redux/slices/authSlice';
import NavBar from './NavBar';
import TopBar from './TopBar';
import * as FirestoreService from '../../views/viewGuardia/alertas/services/firestore';
import tickAudio from '../../views/viewGuardia/alertas/mySounds/police.mp3';

/* sonido para alerta de panico */
const tick = new UIfx(tickAudio, {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100,
});

const useStyles = makeStyles((theme) => ({
  rootAdmin: {
    background: '#fffff',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const DashboardGuardia = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const camaraGarita = useSelector(selectCamaraGarita);
  const user = useSelector(selectUser);
  const conjunto = useSelector(selectConjunto);
  const users = useSelector((state) => state.complex.users);
  const [loading, setLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [dataAlert, setDataAlert] = useState({});
  const [loadingPermitir, setLoadingPermitir] = useState(false);

  const handleOpenSnack = (data, id) => {
    setDataAlert({ data, id });
    setOpenSnack(true);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const listenAlert = (idConjunto) => {
    let hasAlert = false
    const unsuscriber = FirestoreService.getAlerta(
      {
        next: (querySnapshot) => {
          const alert = querySnapshot.docs[0] || null;
          setLoading(false);

          if (alert) {
            hasAlert = true
            tick.play()
            enqueueSnackbar('Ayuda!!!', {
              variant: 'error',
            });
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            });

            let alertMessage = `${alert.data().DatosUsuario}\n${alert.data().Detalle}`

            const alertOriginComplex = alert.ref.path.split('/')[1]
            if (user.complexs.length > 0) {
              const originComplex = user.complexs?.find(complex => complex.uid === alertOriginComplex)
              if (originComplex) { alertMessage += `\nen el conjunto ${originComplex.nombre}` }
            }

            swalWithBootstrapButtons
              .fire({
                title: 'Necesito ayuda',
                text: alertMessage,
                icon: 'error',
                imageUrl: alert.data().Imagen,
                imageWidth: 400,
                imageHeight: 200,
                confirmButtonText: 'Detalle',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  if (user.complexs.length > 0) {
                    dispatch(getComplex(alertOriginComplex)).unwrap()
                  }
                  history.push('/guardia/alerta')
                }
              })
          }
        },
        error: () => {
          enqueueSnackbar('Error al obtener registro de alertas', { variant: 'error' })
        }
      },
      idConjunto,
    );
    return { unsuscriber, hasAlert }
  };

  const enviarCorreo = async (values, params) => {
    try {
      const body = {
        id_conjunto: conjunto.id,
        imagen_conjunto: "",
        residente_nombre: `${values.nombre_destino} ${values.apellido_destino}`,
        residente_casa: values.casa_destino,
        tipo: "seguridad",
        fecha_hora: new Date(),
        asunto: "Notificación de acceso [no-reply]",
        evento: values.tipo_visita,
        motivo: values.tipo_visita,
        observacion: values.observaciones_entrada,
        visitante_nombre: `${values.nombre_persona_ingreso} ${values.apellido_persona_ingreso}`,
        visitante_dni: values.cedula_persona_ingreso,
        visitante_placa: values.placa_vehiculo_ingreso,
        visitante_placa_color: values.color_vehiculo_ingreso,
        fotos: [
          values.imagen_rostro,
          values.imagen_vehiculo,
          values.imagen_cedula,
        ],
        destinatario: params.get("correo"),
        correo: conjunto?.CorreoPersonalizado || undefined,
      };

      const { status } = await axiosInstance.post(
        "correo/enviar_correos_seguridad/",
        body
      );

      if (status === 200) {
        enqueueSnackbar("Se envió correo de notificación.", {
          variant: "success",
        });
      }
    } catch (err) {
      enqueueSnackbar("Error al enviar correo de notificación.", {
        variant: "error",
      });
    }
  };

  const closeQrAlert = () => {
    setLoadingPermitir(true);
    FirestoreService.deleteVisitaAnticipadaQrByConjunto(
      conjunto.id,
      dataAlert?.id
    )
      .then(() => {
        if (dataAlert?.data?.Tipo === "success") {
          enqueueSnackbar("Entrada Registrada", {
            variant: "success",
          });
        }
        setLoadingPermitir(false);
        handleCloseSnack();
      })
      .catch(() => { });
  };

  const permitirEntrada = async () => {
    setLoadingPermitir(true);
    const visitaSnapshot = await FirestoreService.getVisitaAnticipadaByHash(
      conjunto.id,
      dataAlert.data.Hash
    );
    const visitaDoc = visitaSnapshot.docs[0];
    const visitaAnticipada = { id: visitaDoc.id, ...visitaDoc.data() };
    const selectedUser = users.find(
      (us) => visitaAnticipada.DestinoId === us.id
    );
    const params = new URLSearchParams({
      tipo_entrada: 1,
      nombre_persona_ingreso: visitaAnticipada?.Nombre,
      apellido_persona_ingreso: visitaAnticipada?.Apellido,
      cedula_persona_ingreso: visitaAnticipada?.Cedula.trim(),
      placa_vehiculo_ingreso: visitaAnticipada?.PlacaVehiculo || "",
      color_vehiculo_ingreso: visitaAnticipada?.ColorVehiculo || "",
      nombre_destino:
        visitaAnticipada?.DestinoId === "Otro" ? "" : selectedUser?.Nombre,
      apellido_destino:
        visitaAnticipada?.DestinoId === "Otro" ? "" : selectedUser?.Apellido,
      casa_destino:
        visitaAnticipada?.DestinoId === "Otro"
          ? visitaAnticipada?.LugarDestinoOtro
          : selectedUser?.Casa,
      id_destino:
        visitaAnticipada?.DestinoId === "Otro" ? "" : selectedUser?.idUser,
      id_conjunto: conjunto.id,
      tipo_visita: visitaAnticipada?.TipoVisita,
      tiempo_estimado_salida:
        visitaAnticipada?.TiempoEstimadoSalida &&
          visitaAnticipada?.TiempoEstimadoSalida !== "Indefinido"
          ? visitaAnticipada?.TiempoEstimadoSalida
          : "",
      observaciones_entrada: `${visitaAnticipada?.Observacion || ""}`,
      correo:
        visitaAnticipada?.DestinoId === "Otro" ? "" : selectedUser?.Correo,
      camaraGarita,
    });
    const res = await axiosInstance.post(
      `api/bitacora_digital/${conjunto.id}/`,
      params
    );
    if (res.status === 200) {
      enqueueSnackbar(
        <>
          ¡Error en el registro!
          <br />
          No se ha registrado una última salida para este usuario
        </>,
        {
          variant: "error",
        }
      );
      handleCloseSnack();
      setLoadingPermitir(false);
      return;
    }
    if (res.status === 201) {
      enqueueSnackbar("Registro ingresado con éxito", {
        variant: "success",
      });
      await FirestoreService.updateEstadoVisita(
        conjunto.id,
        visitaAnticipada.id,
        true
      );
      const paramsPush = {
        titulo: "Ingreso de visita",
        mensaje: `Tu visita ${visitaAnticipada?.Nombre} ${visitaAnticipada?.Apellido} acaba de llegar.`,
        tokens_dispositivo: selectedUser?.tokens || [],
      };
      try {
        await axiosInstance.post("qrcode/envia_push/", paramsPush);
        setLoadingPermitir(false);
        handleCloseSnack();
      } catch (error) {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(
            "Error en la respuesta del servidor:",
            error.response.status
          );
        }
        setLoadingPermitir(false);
        handleCloseSnack();
      }
      await FirestoreService.deleteVisitaAnticipadaQrByConjunto(
        conjunto.id,
        dataAlert.id
      );
      enviarCorreo(res.data, params);
      handleCloseSnack();
    }
  };

  /* Para notificaciones de alertas de pánico */
  useEffect(() => {
    let unsuscribers = []
    let isMounted = true
    if (isMounted) {
      if (user.complexs?.length > 0) {
        unsuscribers = user.complexs.map(complex => listenAlert(complex.uid))
      } else {
        const suscriber = listenAlert(user.ConjuntoUidResidencia)
        unsuscribers.push(suscriber)
      }
    }

    return () => {
      unsuscribers.forEach(res => { res?.unsuscriber?.() })
      isMounted = false
      setLoading(true)
    };
  }, [conjunto.id]);

  /** Para las notificaciones al guardia */
  useEffect(() => {
    const unsub = FirestoreService.getVisitaAnticipadaQrByConjunto({
      next: (query) => {
        query.docChanges().forEach(change => {
          if (change?.type === "added") {
            const alertQr = change.doc?.data?.()
            if (alertQr && moment(alertQr.TiempoEntrada.toDate()).isBetween(moment().startOf('day'), moment().endOf('day'))) {
              handleOpenSnack(alertQr, alertQr.id);
            }
          }
        })
      }
    },
      conjunto.id,
      camaraGarita
    );

    return unsub;
  }, [camaraGarita, conjunto]);

  /* Para entradas por Control Acceso */
  useEffect(() => {
    const unsub = FirestoreService.getEntradaCA({
      next: (query) => {
        query.docs.forEach((doc) => {
          const data = doc.data()
          const notificacion = {
            title: 'Entrada por Control de Acceso',
            message: data.Mensaje,
            type: 'success',
            container: 'top-left',
          };
          Store.addNotification({
            ...notificacion,
            onRemoval: async () => {
              await FirestoreService.deleteEntradaCA(doc.id)
            },
            dismiss: {
              duration: 2000,
              onScreen: true,
              pauseOnHover: true,
              showIcon: true
            },
          });
        });
      }
    }, conjunto.id);

    return unsub
  }, [conjunto.id]);

  if (loading) {
    return <SplashScreen />;
  }
  return (
    <div className={classes.rootAdmin}>
      <TopBar />

      <Snackbar
        open={openSnack}
        autoHideDuration={dataAlert?.data?.Tipo === "warning" ? 20000 : 5000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          handleCloseSnack();
        }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              dataAlert?.data?.Tipo === "warning"
                ? "#d32f2f"
                : dataAlert?.data?.Tipo === "success"
                  ? "#43a047"
                  : "#092147",
            padding: "16px",
          }}
          message={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <h6>{dataAlert?.data?.Titulo}</h6>
                {dataAlert?.data?.Mensaje}
                <br />
              </div>
              <div
                style={{
                  marginTop: "12px",
                  display: "flex",
                  gap: "8px",
                }}
              >
                {dataAlert?.data?.Tipo === "warning" && (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={loadingPermitir}
                    style={{
                      color: "black",
                      borderColor: "white",
                      backgroundColor: "white",
                    }}
                    onClick={() => {
                      permitirEntrada();
                    }}
                  >
                    <Check fontSize="small" /> Aprobar la entrada
                  </Button>
                )}
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    color: "black",
                    borderColor: "white",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    closeQrAlert();
                  }}
                >
                  <Close fontSize="small" /> Cerrar
                </Button>
              </div>
            </div>
          }
        />
      </Snackbar>

      <NavBar />

      <AlertGarita />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const AlertGarita = () => {
  const currentGarita = useSelector(selectCamaraGarita)
  const conjunto = useSelector((state) => state.complex.complex)
  const dispatch = useDispatch()
  const [showAler, setShowAler] = useState(false)
  const [garitas, setGaritas] = useState([])

  const handleOpen = () => setShowAler(true)

  const handleConfirmGarita = (numGarita) => {
    dispatch(selectGarita(numGarita))
    setShowAler(false)
  }

  useEffect(() => {
    if (conjunto.id) {
      axiosInstance.get('api/camaras_bitacora/', { params: { id_conjunto: conjunto.id } })
        .then(({ data }) => {
          if (data.count > 1) {
            const groupGaritas = data.results.map((garita) => garita.camara_garita)
            setGaritas(groupGaritas)
            handleOpen()
          } else {
            handleConfirmGarita(null)
          }
        })
        .catch(() => { })
    }
  }, [conjunto.id])

  if (garitas <= 0 || Boolean(currentGarita)) {
    return null
  }
  return (
    <Dialog
      open={showAler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>CONJUNTO CON MULTIPLES GARITAS</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Para continuar debe seleccionar la garita en la que se encuentra:
        </DialogContentText>
        <Grid container spacing={2} justifyContent="space-between">
          {garitas.map((garita) => (
            <Grid item xs={12} md={6} key={garita}>
              <Typography variant="h4">
                Garita
                {' '}
                {garita}
              </Typography>
              <br />
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleConfirmGarita(garita)}
              >
                Seleccionar
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

DashboardGuardia.propTypes = {
  children: PropTypes.node,
};

DashboardGuardia.defaultProps = {
  children: '',
};

export default DashboardGuardia;
