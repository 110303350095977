import _ from "lodash";
import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import { esES as coreEs } from "@material-ui/core/locale";
import { esES } from "@mui/x-data-grid";

import Colors from "src/components/Common/Colors";
import { THEMES } from "../constants";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiTable: {
      root: {
        minWidth: 700,
      },
    },
    MuiAvatar: {
      img: {
        width: 25,
        height: 25,
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButton: {
      // The default props to change
      size: "small",
      color: "primary",
    },
    MuiTable: {
      size: "small",
    },
    MuiIconButton: {
      color: "primary",
    },
    MuiIcon: {
      color: "primary",
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: Colors.DARK_BLUE,
      },
      background: {
        default: colors.common.white,
        paper: colors.common.white,
      },
      primary: {
        main: Colors.DARK_BLUE,
        contrastText: "#fff",
      },
      secondary: {
        main: Colors.LIGTH_BLUE,
        contrastText: "#fff",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#8a85ff",
      },
      secondary: {
        main: "#8a85ff",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
        white: "#ffffff",
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#2a2d3d",
        dark: "#222431",
        paper: "#2a2d3d",
      },
      primary: {
        main: "#a67dff",
      },
      secondary: {
        main: "#a67dff",
      },
      text: {
        primary: "#f6f5f8",
        secondary: "#9699a4",
      },
    },
    shadows: strongShadows,
  },
];

export const customTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.name);

  if (!themeOptions) {
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge(baseOptions, themeOptions, config.custom),
    esES,
    coreEs
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
